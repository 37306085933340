import React, { useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import AOS from 'aos';

import Seo from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"

import Icone from "../images/icon.png"
import PDVideo from "../images/pdv.mp4"


const IndexPage = () => {

  useEffect(() => {
     AOS.init()
  }, []);


  return (
    <main>
		<Seo
			title="Sistema Completo de Controle de Estoque Vendas e PDV | wEstoque 🏠"
			description="Automatize sua loja com o wEstoque o sistema de PDV online que gerencia estoque vendas e financeiro de múltiplas lojas e empresas. Controle tudo em tempo real com facilidade segurança e mobilidade. Experimente grátis e otimize suas operações agora!"	
			keywords={[
				`Sistema de controle de estoque`,
				`Gestão de PDV online`,
				`ERP para múltiplas lojas`,
				`Controle financeiro e fiscal`,
				`Gestão de vendas e estoques`,
				`Emissão de notas fiscais`,
				`Software de gestão empresarial`,
				`Controle de múltiplos caixas`,
				`Gestão integrada de empresas`,
				`pdv online`,
				`gestão financeira`,
				`controle de vendas`,
				`multi empresas e lojas`,
				`sistema ERP`,
				`controle de múltiplos caixas`,
				`software de ponto de venda`,
				`gestão de comissionamento`,
				`gerenciamento de estoque em nuvem`,
				`automatização de lojas`,
				`wEstoque`			
			]}
		/>

      <Header />
 
      <section style={{paddingTop: `80px`}} className="overflow-x-hidden">

        <div className="max-w-6xl mx-auto">


        	<div className="py-8 md:py-15">
                    
            
            <div className="grid gap-5 sm:gap-10 ">
                
							<div className="md:grid md:grid-cols-12 md:gap-6 items-center p-3">
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1 aos-init aos-animate hidden md:block" data-aos="fade-in">

									<img src={Icone} alt="Icone" className="absolute animate-pulse" width={30} style={{zIndex: 999, right:`6em`, top:`68px`}} />
									<img src={Icone} alt="Icone" className="absolute animate-pulse" width={50} style={{zIndex: 999, right:`10em`}} />
									<img src={Icone} alt="Icone" className="absolute animate-pulse" width={50} style={{zIndex: 999, right:`13em`, top:`85px`}} />
									<img src={Icone} alt="Icone" className="absolute animate-bounce" width={60} style={{zIndex: 999, left:`3.7em`, top: `205px`}} />
									<StaticImage
										src="../images/home-topo-westoque.png"
										alt="Sistema wEstoque - PDV online, financeiro e fiscal"
										placeholder="blurred"
										layout="fixed"
										quality={100}
										className="max-w-full mx-auto md:max-w-none h-auto"
									/>
								</div>
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 aos-init aos-animate" data-aos="fade-right">
									<div className="max-w-4xl mx-auto text-center pb-12 md:pb-16">
										<h1 className="text-center text-5xl font-bold" >
											Controle <span className="bg-clip-text text-transparent bg-gradient-to-r from-westoque-400 to-westoque-500">ESTOQUE</span>, <span className="bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-indigo-600">VENDAS</span>, <span className="bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-green-600">FINANCEIRO</span> e <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-purple-600">NOTAS</span>&nbsp;<span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-purple-600">FISCAIS</span>
										</h1>
									</div>
									<div className="md:pr-4 lg:pr-12 xl:pr-16">
											<div className="font-semibold text-xl text-purple-600 mb-2">⏰ Valorize seu tempo!</div>
											<p className="text-xl text-gray-700 mb-4">Automatize a sua loja com nosso sistema de PDV web para gerenciar, faturar e controlar as vendas e estoque de sua(s) empresa(s) de forma completa.<br/><br/><span className="bg-yellow-100 font-bold p-1 shadow-md shadow-yellow-200/80">Teste grátis, use agora:</span></p>
									</div>
								
									<form className="w-full max-w-sm" action="/usar">
										<div className="absolute flex w-full flex-wrap items-stretch mb-3 border-2 border-gray-200 rounded-xl hover:border-gray-300 focus:outline-none focus:border-yellow-500 transition-colors shadow-lg">
											<input type="email" id="txtEmail" name="txtEmail" className="w-full pl-3 pr-10 py-2 rounded-xl border-0" placeholder="Digite Seu E-mail"  />
											<button type="submit" className="block w-25 h-7 text-center font-extrabold text-sm leading-0 absolute top-2 right-2 focus:outline-none hover:text-gray-900 transition-colors text-green-500"><span role="img" aria-label="go emoji">✅&nbsp; QUERO USAR!</span> </button>  
										</div>
									</form>

								</div>
							</div>

							<div className="md:grid md:grid-cols-3 md:gap-2 items-center align-top row-start-auto mt-14 p-3">
								<div className="md:col-span-2 aos-init aos-animate" data-aos="fade-in">
									<div className="font-semibold text-xl text-purple-600 mb-2">👋🏻&nbsp;Oi! Descubra o que o wEstoque faz!</div>
									<p className="text-xl text-gray-700 dark:text-gray-700 mb-4">O sistema funciona na nuvem e foi desenvolvido para trabalhar com múltiplos estoques e lojas (empresas). Assim nosso software comercial garante mobilidade e gerenciamento centralizado sobre todos os estoques online e vendas das lojas em tempo real.</p>
								</div>
								<div className="text-left  md:col-span-1 md:text-right aos-init aos-animate" data-aos="fade-up">
									<div className="font-semibold text-xl text-purple-600 mb-2">🤔&nbsp;Precisa de Ajuda?</div>
									<p className="text-md text-gray-700 dark:text-gray-700">Fale com um especialista em vendas.</p>
									<a href="https://api.whatsapp.com/send?phone=5541991543922&text=Oi%2C%20quero%20saber%20mais%20sobre%20o%20wEstoque" target="_blank" rel="noreferrer" className="cursor-pointer">
										<p className="text-md px-4 py-2 hover:transition-transform ease-in-out duration-1000 hover:scale-110 hover:bg-green-500 hover:text-bold rounded-lg text-green-600 hover:text-white max-w-sm cursor-pointer float-right xs:mr-2" style={{width:`225px`}}>
											<span role="img" aria-label="go emoji">💬</span>&nbsp;&nbsp;Chamar no Whatsapp
										</p>
									</a>
									<br/>
								</div>
							</div>




							<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">

								<div className="bg-white p-5 rounded-lg shadow-md m-3 min-w-min aos-init aos-animate mt-0" data-aos="fade-left">
									<StaticImage
										src="../images/icone-multiempresas.png"
										alt="Controle multiplas empresas e lojas. Venda e gerencie todas as suas lojas online."
										placeholder="blurred"
										layout="fixed"
										quality={100}
										width={78}
									/>
									<div className="mt-4 mb-10">
										<span className="bg-clip-text text-transparent bg-gradient-to-r from-westoque-400 to-westoque-500 text-3xl">Multi Lojas</span>
										<p className="text-gray-700 text-xl">Controle o estoque, vendas e representantes de todas suas empresas em um único sistema.</p>
									</div>
								</div>

								<div className="bg-white p-5 rounded-lg shadow-md m-3 min-w-min aos-init aos-animate mt-0" data-aos="fade-left" >
									<StaticImage
										src="../images/icone-pdv.png"
										alt="Controle de Ponto de Venda e Caixas. Use diversos caixas."
										placeholder="blurred"
										layout="fixed"
										quality={100}
										
									/>
									
									<div className="mt-4 mb-10">
										<span className="bg-clip-text text-transparent bg-gradient-to-r from-indigo-500 to-indigo-700 text-3xl">Ponto de Venda</span>
										<p className="text-gray-700 text-xl">Tenha total controle de estoque e vendas. Gerencie seu ponto de venda em um único acesso.</p>
									</div>
								</div>


								<div className="bg-white p-5 rounded-lg shadow-md m-3 min-w-min aos-init aos-animate mt-0" data-aos="fade-left" >
									<StaticImage
										src="../images/icone-controlefinanceiro.png"
										alt="Controle Financeiro. Gerencie suas contas e receitas."
										placeholder="blurred"
										layout="fixed"
										quality={100}
										width={81}
									/>
									
									<div className="mt-4 mb-10">
										<span className="bg-clip-text text-transparent bg-gradient-to-r from-green-500 to-green-700 text-3xl">Financeiro</span>
										<p className="text-gray-700 text-xl">Faça a gestão de suas contas e comissionamento de maneira fácil.</p>
									</div>
								</div>


								<div className="bg-white p-5 rounded-lg shadow-md m-3 min-w-min aos-init aos-animate mt-0" data-aos="fade-left" >
									<StaticImage
										src="../images/icone-controleestoque.png"
										alt="Gerencie vários estoques e localidades. Venda de diversos lugares ao mesmo tempo."
										placeholder="blurred"
										layout="fixed"
										quality={100}
										height={75}
									/>
									
									<div className="mt-4 mb-10">
										<span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-purple-700 text-3xl">Estoque</span>
										<p className="text-gray-700 text-xl">Integre e gerencie seus estoques e produtos de forma rápida e segura.</p>
									</div>
								</div>

							</div>

							<div className="grid grid-cols-1 text-center aos-init aos-animate" data-aos="fade-in">
								<div className="font-semibold text-3xl text-purple-600 mb-2">Software de Ponto de Venda</div>
							</div>                      


							<div className="md:grid md:grid-cols-12 md:gap-6 items-center">
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-7 mb-8 md:mb-0 md:order-1 aos-init aos-animate hidden md:block" data-aos="fade-up">
									<video width="1024" height="880" loop={true} autoPlay="autoplay" muted className="rounded-lg shadow-2xl max-w-full mx-auto md:max-w-none h-auto -mt-10" style={{width: `1000`}}>
										<source src={PDVideo} type="video/mp4" />
									</video>
								</div>
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-5">
									
									<div className="md:pr-4 lg:pr-12 xl:pr-16 p-3">
											
											<div className="max-w py-4 px-8 bg-white shadow-lg rounded-lg my-20 aos-init aos-animate" data-aos="fade-right">
												<div className="flex justify-center md:justify-end -mt-16">
												<StaticImage
													src="../images/icone-facilusar.png"
													alt="Sistema de Controle de Estoque e ERP fácil de usar."
													placeholder="blurred"
													layout="fixed"
													quality={100}
												/>
												</div>
												<div>
													<h2 className="text-westoque-400 text-xl font-semibold">Fácil de Usar e Configurar</h2>
													<p className="mt-2 text-gray-700">O controle de PDV foi desenvolvido para ser simples e eficaz. Basta alguns cliques/toques para o vendedor lançar a venda. O melhor sistema de vendas.</p>
												</div>
											</div>

											<div className="max-w py-4 px-8 bg-white shadow-lg rounded-lg my-20 aos-init aos-animate" data-aos="fade-right">
												<div className="flex justify-center md:justify-end -mt-16">
													<StaticImage
														src="../images/icone-multiploscaixas.png"
														alt="Controle de Múltiplos Caixas e PDV."
														placeholder="blurred"
														layout="fixed"
														quality={100}
													/>
												</div>
												<div>
													<h2 className="text-westoque-400 text-xl font-semibold">Controle de Múltiplos Caixas</h2>
													<p className="mt-2 text-gray-700">Sem limitações de caixas registradoras! Saiba exatamente as vendas e recebimentos de cada caixa físico.</p>
												</div>
											</div>

											<div className="max-w py-4 px-8 bg-white shadow-lg rounded-lg my-20 aos-init aos-animate" data-aos="fade-right">
												<div className="flex justify-center md:justify-end -mt-16">
													<StaticImage
														src="../images/icone-controle.png"
														alt="Controle de Acesso e Comissionamento Total."
														placeholder="blurred"
														layout="fixed"
														quality={100}
													/>
												</div>
												<div>
													<h2 className="text-westoque-400 text-xl font-semibold">Controle de Acesso Total</h2>
													<p className="mt-2 text-gray-700">Controle o que cada vendedor/usuário pode acessar no sistema. Desconto máximo, comissionamento individual e muito mais.</p>
												</div>
											</div>

										</div>
									</div>
							</div>


							<div className="md:grid md:grid-cols-12 md:gap-6 items-center">
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1 aos-init aos-animate hidden md:block" data-aos="fade-in">
									<StaticImage
										src="../images/call-pdv-cliente.png"
										alt="Sistema Completo de Controle de Estoque, Vendas e Financeiro"
										placeholder="blurred"
										layout="fullWidth"
										quality={100}
									/>									
								</div>
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 aos-init aos-animate text-center" data-aos="fade-right">
									<div className="max-w-4xl mx-auto text-center pb-6 md:pb-8">
										<h1 className="text-center text-5xl font-bold mb-2 p-1" >
											<span className="underline decoration-westoque-400">Sistema Completo</span> e <span className="underline decoration-westoque-400">Eficiente</span> para<br/><span className="underline decoration-sky-500">Sua Empresa</span>!
										</h1> 
										<div className="text-xl text-gray-700 mb-2 w-full">Com o <b className="text-2xl">w<span className="text-westoque-400">Estoque</span></b> você pode focar no seu negócio!</div>
									</div>
									<a href="/usar" className="max-w-xs font-medium w-full inline-flex items-center justify-center border border-transparent px-4 py-2 my-2 rounded-md text-white bg-westoque-400 hover:bg-westoque-500 hover:transition-transform hover:scale-110 shadow-md shadow-westoque-400/50 transition duration-150 ease-in-out">EXPERIMENTE GRÁTIS</a>
								</div>
							</div>

						</div>
					</div>
				</div>
	 </section>


	<Footer />


    </main>
  )
}

export default IndexPage
